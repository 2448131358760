<template>
    <div>
        <Faq />
    </div>
</template>

<script>
import Faq from '../components/DashBoard/Faq'


export default {
    name: 'Home01',
    components: {
        Faq,
    }
}
</script>