<template>
    <div>
        <Team />
    </div>
</template>

<script>
import Team from '../components/DashBoard/Team'


export default {
    name: 'Home01',
    components: {
        Team,
    }
}
</script>