<template>
    <div>
        <Feature />
    </div>
</template>

<script>
import Feature from '../components/DashBoard/Feature'


export default {
    name: 'Home01',
    components: {
        Feature,
    }
}
</script>