<template>
    <div>
        <Creation />
    </div>
</template>

<script>
import Creation from '../components/DashBoard/Creation'


export default {
    name: 'Home01',
    components: {
        Creation,
    }
}
</script>