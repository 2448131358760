
<template>
    <section class="section-team team-8 mobie-pb0" id="team">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="flat-tabs">
                        <Tab />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Tab from "../Tab/TabTeam/Tab";

import "./styles.scss";

export default {
    name: "Feature",
    components: {
        Tab,
    }
};
</script>
            
            