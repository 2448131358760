import img1 from '../assets/images/item/item-banner-1.png';
import img2 from '../assets/images/item/item-banner-2.png';
import img3 from '../assets/images/item/item-banner-3.png';
import img4 from '../assets/images/item/item-banner-4.png';
import img5 from '../assets/images/item/item-banner-5.png';
import img6 from '../assets/images/item/item-banner-6.png';
import img7 from '../assets/images/item/item-banner-7.png';

export const dataBanner = [
    {
        id: 1,
        img:img1,
    },
    {
        id: 2,
        img:img2,
    },
    {
        id: 3,
        img:img3,
    },
    {
        id: 4,
        img:img4,
    },
    {
        id: 5,
        img:img5,
    },
    {
        id: 6,
        img:img6,
    },
    {
        id: 7,
        img:img7,
    },
]

export default dataBanner;