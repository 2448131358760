<template>
    <div>
        <RoadMap />
    </div>
</template>

<script>
import RoadMap from '../components/DashBoard/RoadMap'


export default {
    name: 'Home01',
    components: {
        RoadMap,
    }
}
</script>