export const dataContact = [
    {
        id: 1,
        classAction: 'icon-message',
        content: 'Info@yourcompany.com',
    },
    {
        id: 2,
        classAction: 'icon-Calling',
        content: '+84 0977425031',
    },
    {
        id: 3,
        classAction: 'icon-telegram',   
        content: 'Join us on Telegram',
    },
]

export default dataContact;