export const dataFaq = [
    {
        id: 1,
        title: 'What cryptocurrencies can I use to purchase?',
        content: 'Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.',
        show: false,
    },
    {
        id: 2,
        title: 'What is ICO Crypto?',
        content: 'Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.',
        show: true,
    },
    {
        id: 3,
        title: 'How can I participate in the ICO Token sale?',
        content: 'Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.',
        show: false,
    },
    {
        id: 4,
        title: 'How do I benefit from the ICO Token?',
        content: 'Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.',
        show: false,
    },
    {
        id: 5,
        title: 'How can we buy your NFTs?',
        content: 'Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.',
    },
    {
        id: 6,
        title: 'Who are the team behind the project?',
        content: 'Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.',
    },


    {
        id: 7,
        title: 'How can I track my order?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        show: 'How Can I Participate In The ICO Token Sale?',
        id: 8,
        title: 'What is Shopi. return/exchange policy?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 9,
        title: 'What is Shopi. return/exchange policy?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 10,
        title: 'How can I track my order?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 11,
        title: 'How Can I Participate In The ICO Token Sale?',
        show: 'show',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 12,
        title: 'What is Shopi. return/exchange policy?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 13,
        title: 'What is Shopi. return/exchange policy?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 14,
        title: 'How can I track my order?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 15,
        title: 'How Can I Participate In The ICO Token Sale? ',
        show: 'show',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 16,
        title: 'What Is ICO Crypto?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 17,
        title: 'What is Shopi. return/exchange policy?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 18,
        title: 'How can I track my order?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 19,
        title: 'How Do I Benefit From The ICO Token? ',
        show: 'show',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 20,
        title: 'What Cryptocurrencies Can I Use To Purchase?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 21,
        title: 'What is Shopi. return/exchange policy?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 22,
        title: 'How can I track my order??',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 23,
        title: 'What Is ICO Crypto?',
        show: 'show',
        content: 'Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.',
    },
    {
        id: 24,
        title: 'What Cryptocurrencies Can I Use To Purchase?',
        content: 'Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.',
    },
    {
        id: 25,
        title: 'How Can I Participate In The ICO Token Sale?',
        content: 'Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.',
    },
    {
        id: 26,
        title: 'How Do I Benefit From The ICO Token?',
        content: 'Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.',
    },

    {
        id: 27,
        title: 'What are the NFTs?',
        show: 'show',
        number:'01',
        content: 'As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.',
    },
    {
        id: 28,
        title: 'How do i get NFTs?',
        number:'02',
         content: 'As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.',
    },
    {
        id: 29,
        title: 'How can we buy your NFTs?',
        number:'03',
         content: 'As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.',
    },
    {
        id: 30,
        title: 'Who are the team behind the project?',
        number:'04',
         content: 'As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.',
    },
]

export default dataFaq;