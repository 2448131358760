<template>
    <div class="home-defi-2 ">
        <Header6 />
        <Banner6 />
        <About6 />
        <Feature5 />
        <Token3 />
        <RoadMap5 />
        <Allocation3 />
        <Team5 />
        <FAQ6 />
        <Blog3 />
        <Footer6 />

    </div>
</template>

<script>
import Header6 from '../components/Header/Header6'
import Banner6 from '../components/Banner/Banner6'
import About6 from '../components/About/About6'
import Feature5 from '../components/Feature/Feature5'
import Allocation3 from '../components/Allocation/Chart3'
import RoadMap5 from '../components/RoadMap/RoadMap5'
import Team5 from '../components/Team/Team5'
import Token3 from '../components/Token/Token3'
import FAQ6 from '../components/FAQ/FAQ6'
import Blog3 from '../components/Blog/Blog3'
import Footer6 from '../components/Footer/Footer6'


export default {
    name: 'HomeNFT01',
    components: {
        Header6,
        Banner6,
        About6,
        RoadMap5,
        Allocation3,
        Feature5,
        Team5,
        Token3,
        FAQ6,
        Blog3,
        Footer6,
    }
}
</script>