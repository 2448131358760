export const dataToken = [
    {
        id: 1,
        title: 'Marketing',
        number: '4%',
    },
    {
        id: 2,
        title: 'Liquidity',
        number: '4%',
    },
    {
        id: 3,
        title: 'REflection',
        number: '2%',
    },
    {
        id: 4,
        title: 'Burned',
        number: '1%',
    },
]

export default dataToken;