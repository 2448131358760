import img1 from '../assets/images/icon/metamask.png';
import img2 from '../assets/images/icon/bitski.png';
import img3 from '../assets/images/icon/fortmatic.png';
import img4 from '../assets/images/icon/WalletConnect.png';
import img5 from '../assets/images/icon/coinbase.png';
import img6 from '../assets/images/icon/authereum.png';
import img7 from '../assets/images/icon/Kaikas.png';
import img8 from '../assets/images/icon/Torus.png';

export const dataWallet = [
    {
        id: 1,
        img: img1,
        title: 'Meta Mask',
        numb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id: 2,
        img: img2,
        title: 'Bitski',
        numb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id: 3,
        img: img3,
        title: 'Fortmatic',
        numb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id: 4,
        img: img4,
        title: 'Wallet Connect',
        numb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id: 5,
        img: img5,
        title: 'Coinbase Wallet',
        numb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id: 6,
        img: img6,
        title: 'Authereum',
        numb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id: 7,
        img: img7,
        title: 'Kaikas',
        numb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id: 8,
        img: img8,
        title: 'Torus',
        numb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
]

export default dataWallet;