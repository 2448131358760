import img1 from '../assets/images/nft/nft-01.jpg';
import img2 from '../assets/images/nft/nft-02.jpg';
import img3 from '../assets/images/nft/nft-03.jpg';
import img4 from '../assets/images/nft/nft-04.jpg';
import img5 from '../assets/images/nft/nft-05.jpg';

export const dataCreation = [
    {
        id: 1,
        img: img1,
        title: 'NFT - Monkey',
    },
    {
        id: 2,
        img: img2,
        title: 'NFT - Monkey',
    },
    {
        id: 3,
        img: img3,
        title: 'NFT - Monkey',
    },
    {
        id: 4,
        img: img4,
        title: 'NFT - Monkey',
    },
    {
        id: 5,
        img: img5,
        title: 'NFT - Monkey',
    },
]

export default dataCreation;