<template>
    <div class="home-defi-3">
        <Header7 />
        <Banner7 />        
        <About7 />
        <Team8 />
        <RoadMap7 />
        <Allocation4 />
        <Feature6 />
        <Token3 />        
        <Parner3 />
        <Blog4 />
        <FAQ7 />
        <Footer7 />

    </div>
</template>

<script>
import Header7 from '../components/Header/Header7'
import Banner7 from '../components/Banner/Banner7'
import About7 from '../components/About/About7'
import Feature6 from '../components/Feature/Feature6'
import RoadMap7 from '../components/RoadMap/RoadMap7'
import Allocation4 from '../components/Allocation/Chart4'
import Team8 from '../components/Team/Team8'
import Token3 from '../components/Token/Token3'
import Parner3 from '../components/Parner/Parner3'
import Blog4 from '../components/Blog/Blog4'
import FAQ7 from '../components/FAQ/FAQ7'
import Footer7 from '../components/Footer/Footer7'


export default {
    name: 'HomeNFT01',
    components: {
        Header7,
        Banner7,
        About7,
        RoadMap7,
        Allocation4,
        Feature6,
        Team8,
        Token3,
        Parner3,
        Blog4,
        FAQ7,
        Footer7,
    }
}
</script>