<template>
    <div class="home-defi-4">
        <Header8 />
        <Banner8 />
        <About8 />
        <Feature7 />
        <RoadMap8 />
        <Token4 />
        <Allocation5 />
        <Team9 />
        <Blog5 />
        <Parner4 />
        <FAQ8 />
        <Footer5 />

    </div>
</template>

<script>
import Header8 from '../components/Header/Header8'
import Banner8 from '../components/Banner/Banner8'
import About8 from '../components/About/About8'
import Feature7 from '../components/Feature/Feature7'
import RoadMap8 from '../components/RoadMap/RoadMap8'
import Allocation5 from '../components/Allocation/Chart5'
import Team9 from '../components/Team/Team9'
import Token4 from '../components/Token/Token4'
import Parner4 from '../components/Parner/Parner4'
import Blog5 from '../components/Blog/Blog5'
import FAQ8 from '../components/FAQ/FAQ8'
import Footer5 from '../components/Footer/Footer5'


export default {
    name: 'HomeNFT01',
    components: {
        Header8,
        Banner8,
        About8,
        RoadMap8,
        Feature7,
        Allocation5,
        Team9,
        Token4,
        Parner4,
        Blog5,
        FAQ8,
        Footer5,
    }
}
</script>