<template>
    <div>
        <TokenSales />
    </div>
</template>

<script>
import TokenSales from '../components/DashBoard/TokenSales'


export default {
    name: 'Home01',
    components: {
        TokenSales,
    }
}
</script>