<template>
    <div class="page-about backgroup-body">
        <Header />
        <PageTitle title='About Us' desc='We designed a brand-new cool design and lots of features, the latest version of the template supports advanced block base scenarios, and more.'  />
        
        <div class="title-style center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3>About Us - Style 1</h3>
                    </div>
                </div>
            </div>
        </div>
        <About />
        
        <div class="title-style center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3>About Us - Style 2</h3>
                    </div>
                </div>
            </div>
        </div>
        <About2 />
        
        <div class="title-style center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3>About Us - Style 3</h3>
                    </div>
                </div>
            </div>
        </div>
        <About4 />
        
        <div class="title-style center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3>About Us - Style 4</h3>
                    </div>
                </div>
            </div>
        </div>
         <About5 />
        
        
        
        <div class="title-style center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3>About Us - Style 5</h3>
                    </div>
                </div>
            </div>
        </div>
        <About6 />
       
       
        <div class="title-style center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3>About Us - Style 6</h3>
                    </div>
                </div>
            </div>
        </div>
        <About3 />
       
        <div class="title-style center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3>About Us - Style 7</h3>
                    </div>
                </div>
            </div>
        </div>
        <About7 />
       
        <div class="title-style center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3>About Us - Style 8</h3>
                    </div>
                </div>
            </div>
        </div>
        <About8 />
        <div class="title-style center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3>About Us - Style 9</h3>
                    </div>
                </div>
            </div>
        </div>
        <About9 />
        <Footer />

    </div>
</template>

<script>
import Header from '../components/Header/Header'
import PageTitle from '../components/PageTitle/PageTitle'
import About from '../components/About/About'
import About2 from '../components/About/About2'
import About3 from '../components/About/About3'
import About4 from '../components/About/About4'
import About5 from '../components/About/About5'
import About6 from '../components/About/About6'
import About7 from '../components/About/About7'
import About8 from '../components/About/About8'
import About9 from '../components/About/About9'
import Footer from '../components/Footer/Footer'


export default {
    name: 'Home01',
    components: {
        Header,
        PageTitle,
        About,
        About2,
        About3,
        About4,
        About5,
        About6,
        About7,
        About8,  
        About9,  
        Footer,
    }
}
</script>